<template>
  <v-container fluid>
    <v-snackbar :value="isError" color="error">
      {{ isError ? getErrorMessage : 'Error inesperado' }}
      <v-btn color="white" text>Cerrar</v-btn>
    </v-snackbar>
    <v-snackbar :value="infoSnackbar" color="success">
      {{ infoSnackbar ? infoSnackbarMessage : '' }}
      <v-btn color="white" text @click="infoSnackbar = false">Cerrar</v-btn>
    </v-snackbar>
    <v-dialog v-model="dialogCrearCobranza" max-width="350px">
      <v-card>
        <v-card-title>Añadir Cobranza</v-card-title>
        <v-divider></v-divider>
        <v-form ref="formCobranza">
          <v-card-text>
            <v-text-field
              v-model="cobranza.montoCobrado"
              outlined
              dense
              label="Monto a Cobrar"
              prefix="S/"
              :hint="`Ingresa un monto entre S/ 1 y S/ ${selectedRow.saldoActual}`"
              persistent-hint
              :rules="rulesMontoCobrado"
            ></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="blue darken-1" text @click="dialogCrearCobranza = false">Cerrar</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click.prevent="cobrarVenta"
              :loading="cobrarVentaLoading"
              :disabled="cobrarVentaLoading"
            >Guardar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogRecogerProducto" scrollable max-width="550px">
      <v-card>
        <v-card-title>Recoger Productos</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-4">
          <div>
            <template
              v-for="(itemProducto, index) in modelProductosRecuperados.productosRecuperados"
            >
              <div class="d-flex flex-row" :key="`${itemProducto}-${index}`">
                <div class="mt-4">
                  <v-icon>mdi-clipboard-arrow-right-outline</v-icon>
                </div>

                <div
                  class="d-flex"
                  :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }"
                  style="width: 100%"
                >
                  <div class="d-flex">
                    <v-text-field
                      dense
                      hide-details
                      readonly
                      outlined
                      v-model="itemProducto.nombreProducto"
                      label="Producto"
                      class="mx-2 mt-2"
                    ></v-text-field>
                  </div>
                  <div class="d-flex">
                    <v-text-field
                      dense
                      outlined
                      :rules="[v => !isNaN(v) || 'Solo se permite números', v => v >= 0 || 'Tiene que ser un número mayor a 0', v => v <= itemProducto.prestamoProductoActual || 'No puedes recuperar más de lo prestado']"
                      v-model="itemProducto.cantidadRecuperada"
                      :suffix="`/${itemProducto.prestamoProductoActual}`"
                      label="Cantidad Recuperada"
                      class="ma-2"
                    ></v-text-field>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="dialogRecogerProducto = false">Cerrar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="postRecojoProducto">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row no-gutters>
      <v-col>
        <v-data-table
          fixed-header
          :headers="getInfoUsuario.rol != 'Usuario' ? headers : headersUsuario"
          :loading="isLoading"
          loading-text="Cargando... Espera un Momento"
          no-data-text="No hay datos para mostrar"
          item-key="idVenta"
          :search="buscarTabla"
          single-expand
          single-select
          show-expand
          @item-expanded="whenExpanded"
          :items="ventasFiltered"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <div>
                <v-toolbar-title>COBRANZAS</v-toolbar-title>
              </div>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-btn icon color="success" class="mx-2" @click="actualizarTabla">
                <v-icon>mdi-reload</v-icon>
              </v-btn>
            </v-toolbar>
            <v-layout class="py-3 d-flex flex-column">
              <v-row>
                <v-col cols="12" sm="12" md="10" class="pb-0">
                  <v-text-field
                    v-model="buscarTabla"
                    prepend-inner-icon="mdi-magnify"
                    label="Buscar en la tabla..."
                    outlined
                    dense
                    class="mx-4 flex-shrink-0 flex-grow-1"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="2" class="py-0">
                  <div class="d-flex align-start">
                    <v-checkbox
                      v-model="selectCobranzasSinCobrar"
                      label="Deuda Pendiente"
                      class="mx-4"
                      v-if="getInfoUsuario.rol == 'Administrador'"
                    ></v-checkbox>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="4">
                  <v-dialog
                    ref="dialogFechaTablaInicio"
                    v-model="modalFechaTablaInicio"
                    :return-value.sync="fechaTablaInicio"
                    width="290px"
                    class="flex-shrink-0 flex-grow-0"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="computedFechaFormateadaInicio"
                        label="Fecha de Inicio"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-on="on"
                        outlined
                        dense
                        class="mx-4"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="fechaTablaInicio" scrollable locale="es">
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modalFechaTablaInicio = false">Cancelar</v-btn>
                      <v-btn text color="primary" @click="actualizarFechaInicio()">OK</v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-dialog
                    ref="dialogFechaTablaFin"
                    v-model="modalFechaTablaFin"
                    :return-value.sync="fechaTablaFin"
                    width="290px"
                    class="flex-shrink-0 flex-grow-0"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="computedFechaFormateadaFin"
                        label="Fecha Final"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-on="on"
                        outlined
                        dense
                        class="mx-4"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="fechaTablaFin" scrollable locale="es">
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modalFechaTablaFin = false">Cancelar</v-btn>
                      <v-btn text color="primary" @click="actualizarFechaFin()">OK</v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="4"
                  class="d-flex justify-end"
                  v-if="getInfoUsuario.rol != 'Usuario'"
                >
                  <v-btn
                    outlined
                    :dark="!!ventas.length"
                    :disabled="!ventas.length"
                    class="mx-4"
                    color="green"
                    @click="exportToExcel"
                  >
                    <v-icon left>mdi-file-excel</v-icon>Exportar
                  </v-btn>
                </v-col>
              </v-row>
            </v-layout>
          </template>
          <template v-slot:item.costoVentaTotal="{ item }">
            S/ {{
            item.costoVentaTotal.toFixed(2)
            }}
          </template>
          <template v-slot:item.saldoActual="{ item }">
            <v-chip :color="getEstado(item.saldoActual)" dark>
              S/ {{
              item.saldoActual.toFixed(2)
              }}
            </v-chip>
          </template>
          <template v-slot:item.fechaVenta="{ item }">{{ formatDateTable(item.fechaVenta) }}</template>
          <template v-slot:expanded-item="{ headers, item }" style="width: 100%">
            <td :colspan="headers.length" v-if="expandedLoading">
              <v-skeleton-loader ref="expSkeleton" type="article" class="mx-auto"></v-skeleton-loader>
            </td>
            <td :colspan="headers.length" v-else>
              <div v-if="Object.keys(venta).length">
                <v-container fluid>
                  <v-layout>
                    <v-row justify="center">
                      <v-col cols="12" sm="12" md="12" class="pb-0">
                        <div
                          class="d-flex justify-space-around"
                          :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }"
                        >
                          <div
                            class="d-flex justify-space-around"
                            :style=" {width: $vuetify.breakpoint.smAndDown ? '95%' : '50%' }"
                          >
                            <p class="mx-1">
                              Productos Vendidos:
                              <span>{{item.cantidadVendidos}}</span>
                            </p>
                            <p class="mx-1">
                              Productos Prestados en Total:
                              <span>{{item.cantidadPrestados}}</span>
                            </p>
                          </div>
                          <div
                            class="d-flex justify-space-around"
                            :style=" {width: $vuetify.breakpoint.smAndDown ? '95%' : '50%' }"
                          >
                            <p>
                              Productos Prestados Actualmente:
                              <span>{{item.cantidadPrestadosActual}}</span>
                            </p>
                            <p>
                              Deuda Actual:
                              <span>S/ {{item.saldoActual.toFixed(2) }}</span>
                            </p>
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" class="pt-0">
                        <v-subheader>Lista de Productos</v-subheader>
                        <v-simple-table dense>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">Producto</th>
                                <th class="text-left">Precio</th>
                                <th class="text-left">Cantidad</th>
                                <th class="text-left">Precio Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(itemProduct, index) in venta.productos"
                                :key="`${venta.detalleVenta.idVenta}-${itemProduct.idProducto}-${index}`"
                              >
                                <td>{{ itemProduct.nombreProducto }}</td>
                                <td>S/ {{ itemProduct.costoUnitario.toFixed(2) }}</td>
                                <td>{{ itemProduct.cantidad }}</td>
                                <td>S/ {{ itemProduct.costoTotalProducto.toFixed(2) }}</td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" class="pt-0">
                        <v-subheader>Lista de Encargados</v-subheader>
                        <v-simple-table dense>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">Nombre</th>
                                <th class="text-left">Cargo</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(itemEncargado, index) in venta.encargados"
                                :key="`${venta.detalleVenta.idVenta}-${itemEncargado.idTrabajador}-${index}`"
                              >
                                <td>{{ itemEncargado.nombre }} {{ itemEncargado.apellidos }}</td>
                                <td>{{ itemEncargado.cargo }}</td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" class="pt-0">
                        <v-subheader>Lista de Cobranzas</v-subheader>
                        <v-simple-table dense>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">ID</th>
                                <th class="text-left">Monto Cobrado</th>
                                <th class="text-left">Fecha de Cobro</th>
                              </tr>
                            </thead>
                            <tbody>
                              <template v-if="venta.cobranzas.length">
                                <tr
                                  v-for="(itemCobranza, index) in venta.cobranzas"
                                  :key="`${venta.detalleVenta.idVenta}-${itemCobranza.idTrabajador}-${index}`"
                                >
                                  <td>{{ itemCobranza.idCobranza }}</td>
                                  <td>S/ {{ itemCobranza.montoCobrado.toFixed(2) }}</td>
                                  <td>{{ formatDateTable(itemCobranza.fechaCobranza) }}</td>
                                </tr>
                              </template>
                              <template v-else>
                                <tr class="text-center">
                                  <td colspan="3" class="text--disabled body-2">No hay datos</td>
                                </tr>
                              </template>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" class="pt-0">
                        <v-subheader>Devolución de Productos</v-subheader>
                        <v-simple-table dense>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">Producto</th>
                                <th class="text-left">Cantidad</th>
                                <th class="text-left">Fecha de Devolución</th>
                              </tr>
                            </thead>
                            <tbody>
                              <template v-if="venta.recojos.length">
                                <tr v-for="(itemRecojo, index) in venta.recojos" :key="index">
                                  <td>{{ itemRecojo.nombreProducto }}</td>
                                  <td>{{ itemRecojo.cantidad }}</td>
                                  <td>{{ formatDateTable(itemRecojo.fechaRecuperacion) }}</td>
                                </tr>
                              </template>
                              <template v-else>
                                <tr class="text-center">
                                  <td colspan="3" class="text--disabled body-2">No hay datos</td>
                                </tr>
                              </template>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                      <v-col cols="12" sm="12" class="d-flex justify-end" v-if="esEncargado">
                        <v-btn
                          color="green"
                          outlined
                          text
                          large
                          :disabled="!selectedRow.saldoActual"
                          @click="dialogCrearCobranza = true"
                          class="mx-1"
                        >
                          <v-icon left>mdi-cash-multiple</v-icon>Cobrar
                        </v-btn>
                        <v-btn
                          color="blue"
                          outlined
                          text
                          large
                          :disabled="!selectedRow.cantidadPrestadosActual"
                          @click="setDialogRecogerProducto"
                          class="mx-1"
                        >
                          <v-icon left>mdi-cart-arrow-down</v-icon>Recoger Producto
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-layout>
                </v-container>
              </div>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import XLSX from 'xlsx'

export default {
  data: () => ({
    verificado: true,
    selectCobranzasSinCobrar: true,
    headers: [
      { text: 'Venta #', value: 'idVenta', align: 'center' },
      { text: 'Cliente', value: 'nombreCliente', align: 'center' },

      {
        text: 'Cobrador',
        value: 'nombreTrabajador',
        align: 'center',
        width: '15%'
      },
      {
        text: '# Items Vendidos',
        value: 'cantidadVendidos',
        align: 'center',
        width: '5%'
      },
      {
        text: '# Items Prestados Actualmente',
        value: 'cantidadPrestadosActual',
        align: 'center',
        width: '5%'
      },
      { text: 'Monto Total (S/)', value: 'costoVentaTotal', align: 'center' },
      { text: 'Saldo Deudor (S/)', value: 'saldoActual', align: 'center' },
      { text: 'Fecha de Venta', value: 'fechaVenta', align: 'center' },
      { text: 'Personas Asignadas', value: 'asignados', align: 'center' },
      { text: '', value: 'data-table-expand' }
    ],
    headersUsuario: [
      { text: 'Venta #', value: 'idVenta', align: 'center' },
      { text: 'Cliente', value: 'nombreCliente', align: 'center' },
      {
        text: '# Items Vendidos',
        value: 'cantidadVendidos',
        align: 'center',
        width: '5%'
      },
      {
        text: '# Items Prestados Actualmente',
        value: 'cantidadPrestadosActual',
        align: 'center',
        width: '5%'
      },
      { text: 'Monto Total (S/)', value: 'costoVentaTotal', align: 'center' },
      { text: 'Saldo Deudor (S/)', value: 'saldoActual', align: 'center' },
      { text: 'Fecha de Venta', value: 'fechaVenta', align: 'center' },
      { text: 'Personas Asignadas', value: 'asignados', align: 'center' },
      { text: '', value: 'data-table-expand' }
    ],
    ventas: [],
    fechaTablaInicio: new Date(new Date().setHours(-5))
      .toISOString()
      .substr(0, 10),
    fechaTablaFin: new Date(new Date().setHours(24))
      .toISOString()
      .substr(0, 10),
    modalFechaTablaInicio: false,
    modalFechaTablaFin: false,
    dataIsloading: false,
    infoSnackbar: false,
    infoSnackbarMessage: '',
    buscarTabla: '',
    expandedLoading: false,
    idVentaSeleccionada: '',
    venta: {},
    listaEncargados: {},
    nuevoRolSeleccionado: '',
    nuevoEncargadoSeleccionado: '',
    listaEncargadosRoles: { encargados: [], roles: [] },
    dialogCrearCobranza: false,
    dialogRecogerProducto: false,
    cobrarVentaLoading: false,
    modelProductosRecuperados: {
      productosRecuperados: []
    },
    selectedRow: {},
    cobranza: {
      idVenta: 0,
      montoCobrado: 0
    }
  }),
  methods: {
    cleanSource(source) {
      return JSON.parse(JSON.stringify(source))
    },

    getEstado(venta) {
      if (venta >= 300) return 'red'
      else if (venta >= 150) return 'yellow darken-2'
      else return 'green'
    },

    getForm(i) {
      this.$emit('change', i)
    },

    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },

    parseDate(date) {
      if (!date) return null
      const [day, month, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },

    formatDateTable(date) {
      let parsedDate = new Date(parseInt(date))
      let options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }
      return `${parsedDate.toLocaleDateString(
        'es-PE',
        options
      )} ${parsedDate.toLocaleTimeString()}`
    },

    async actualizarTabla() {
      let horaCeroInicio = new Date(this.fechaTablaInicio).setHours(24)
      let horaCeroFin = new Date(this.fechaTablaFin).setHours(24)
      //this.setError({ isError: false, error: "" });
      let fechas = { horaCeroInicio, horaCeroFin }
      let response = await this.fetchVentasPorCobrar(fechas)
      this.ventas = response
      //console.log(this.ventas)
    },

    async whenExpanded(row) {
      if (row.value) {
        this.expandedLoading = true
        this.idVentaSeleccionada = row.item.idVenta
        this.selectedRow = row.item
        this.venta = await this.fetchDetalleVenta(this.idVentaSeleccionada)
        this.expandedLoading = false
      }
    },

    actualizarFechaInicio() {
      this.$refs.dialogFechaTablaInicio.save(this.fechaTablaInicio)
      this.actualizarTabla()
    },

    actualizarFechaFin() {
      this.$refs.dialogFechaTablaFin.save(this.fechaTablaFin)
      this.actualizarTabla()
    },

    async cobrarVenta() {
      if (this.$refs.formCobranza.validate()) {
        this.cobrarVentaLoading = true
        this.cobranza.idVenta = this.selectedRow.idVenta
        let response = await this.postCobrarVenta(this.cobranza)
        this.cobrarVentaLoading = false
        if (!response) {
          this.dialogCrearCobranza = false
          this.cobranza = {
            idVenta: 0,
            montoCobrado: 0
          }
          await this.actualizarTabla()
          this.venta = await this.fetchDetalleVenta(this.idVentaSeleccionada)
        }
      }
    },

    async setDialogRecogerProducto() {
      this.modelProductosRecuperados.productosRecuperados = this.venta.productos.map(
        v => ({ ...v, cantidadRecuperada: 0 })
      )
      this.dialogRecogerProducto = true
    },

    async postRecojoProducto() {
      let payload = this.cleanSource({
        idVenta: this.idVentaSeleccionada,
        body: this.modelProductosRecuperados
      })
      let response = await this.postRecuperarProductos(payload)
      if (!response) {
        await this.actualizarTabla()
        this.venta = await this.fetchDetalleVenta(this.idVentaSeleccionada)
        this.dialogRecogerProducto = false
        this.modelProductosRecuperados = {
          productosRecuperados: []
        }
        this.selectedRow.cantidadPrestadosActual = this.venta.productos.reduce(
          (sum, item) => sum + parseFloat(item.prestamoProductoActual || 0),
          0
        )
      }
    },

    exportToExcel() {
      let resource = this.cleanSource(this.ventas)

      for (let i = 0; i < resource.length; i++) {
        resource[i].fechaVenta = this.formatDateTable(resource[i].fechaVenta)
      }

      let dataExported = XLSX.utils.json_to_sheet(resource, {
        header: [
          'idVenta',
          'idCliente',
          'nombreCliente',
          'fechaVenta',
          'costoVentaTotal',
          'saldoActual',
          'cantidadPrestados',
          'cantidadPrestadosActual',
          'cantidadVendidos',
          'asignados'
        ]
      })

      dataExported['A1'].v = 'ID de Venta'
      dataExported['B1'].v = 'ID de de Cliente'
      dataExported['C1'].v = 'Clienter'
      dataExported['D1'].v = 'Fecha de Venta'
      dataExported['E1'].v = 'Monto Total'
      dataExported['F1'].v = 'Saldo Deudor'
      dataExported['G1'].v = '# Items Prestados'
      dataExported['H1'].v = '# Items Faltan Recoger'
      dataExported['I1'].v = '# de Items Vendidos'
      dataExported['J1'].v = 'Personas Asignadas'

      const workbook = XLSX.utils.book_new()
      const filename = `${new Date().getTime()}-Cobranzas`
      XLSX.utils.book_append_sheet(workbook, dataExported, filename)
      XLSX.writeFile(workbook, `${filename}.xlsx`)
    },

    ...mapActions('user', [
      'fetchVentasPorCobrar',
      'postRecuperarProductos',
      'postCobrarVenta'
    ]),

    ...mapActions('venta', ['fetchDetalleVenta']),

    ...mapMutations('user', ['setError', 'loadingRequest'])
  },
  computed: {
    computedFechaFormateadaInicio() {
      return this.formatDate(this.fechaTablaInicio)
    },

    computedFechaFormateadaFin() {
      return this.formatDate(this.fechaTablaFin)
    },

    getFechaTabla() {
      const offsetDate = new Date(this.fechaTabla).setHours(
        new Date(this.fechaTabla).getHours() - 5
      )
      return new Date(offsetDate).toISOString().substr(0, 10)
    },
    fechaFormateada() {
      return new Date(this.fechaTabla).toISOString().substr(0, 10)
    },

    esEncargado() {
      let encontrado = false
      for (let i = 0; i < this.venta.encargados.length; i++) {
        if (
          this.venta.encargados[i].idTrabajador ==
            this.getInfoUsuario.idTrabajador &&
          this.venta.encargados[i].idCargo == 3
        ) {
          encontrado = true
          break
        }
      }
      return encontrado
    },

    getErrorMessage() {
      switch (this.getError.errorMessage) {
        case 'Network Error':
          return 'Error al intentar conectarse al servidor, contacte al administrador'
        default:
          return this.getError.errorMessage
      }
    },

    rulesMontoCobrado() {
      return [
        v => (v && !isNaN(v)) || 'Ingresa un número',
        v =>
          (v > 0 && v <= this.selectedRow.saldoActual) ||
          `Ingresar un monto entre S/ 1 y S/ ${this.selectedRow.saldoActual}`
      ]
    },

    ventasFiltered() {
      if (this.selectCobranzasSinCobrar) {
        return this.ventas.filter(
          item => item.saldoActual > 0 || item.cantidadPrestadosActual > 0
        )
      } else {
        return this.ventas
      }
    },

    ...mapGetters('user', [
      'isError',
      'getError',
      'isLoading',
      'getInfoUsuario'
    ])
  },

  mounted: async function() {
    this.actualizarTabla()
  }
}
</script>
